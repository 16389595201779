import { sectionStyle } from 'holded/lib/styles';
import { cleanDoubleSlash } from 'holded/lib/utils';
import { NavbarItem } from 'holded/modules/cms/domain/page';
import { MenuState } from 'holded/shared/ui/components/Menu/Menu';

export type SingleProps = {
  nav: NavbarItem;
  handleMenuItem: (menu: MenuState) => void;
  desktop: boolean;
  dark: boolean;
};

const MenuSingle = ({ nav, handleMenuItem, desktop, dark }: SingleProps) => {
  const handleHideMenu = () => {
    if (!desktop) return;

    handleMenuItem({ name: '', state: false });
  };

  const textColor = dark
    ? 'text-white hover:text-gray-strong'
    : `${sectionStyle.textColor.default} hover:text-gray-500`;

  return (
    <ul className={'px-5 py-4 xl:p-0'} onMouseEnter={handleHideMenu}>
      <li>
        <a
          href={
            nav.attributes.href.startsWith('http') ? nav.attributes.href : `/${cleanDoubleSlash(nav.attributes.href)}`
          }
          className={`${textColor} xl:${textColor} text-base font-normal`}
        >
          {nav.attributes.name}
        </a>
      </li>
    </ul>
  );
};

export default MenuSingle;
